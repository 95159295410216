<!--ToastHasIcon.vue-->
<template>
  <transition name="fade">
    <div
      v-if="showing"
      class="fixed flex items-center gap-8 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-full toat-bg px-24 py-12 z-[2510]"
    >
      <s-icon :icon="icon" size="3xl" class="text-white" />
      <s-text as="span" role="text1" class="text-white">{{ text }}</s-text>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { AllIcons } from '@stove-ui/vue';
import { defineEmits, defineProps, ref, watch } from 'vue';

const TOAST_TIME_OUT = 1000;

const emit = defineEmits(['update:showing']); // Adjust the import path as necessary

const props = withDefaults(
  defineProps<{
    icon?: AllIcons | undefined;
    text: string;
    showing: boolean;
  }>(),
  {
    icon: 'ic-v2-object-delete-line',
    text: '',
    showing: false
  }
);

const showTimeout = ref();

watch(
  () => props.showing,
  (newVal: boolean) => {
    if (newVal) {
      clearTimeout(showTimeout.value);
      showTimeout.value = setTimeout(() => {
        emit('update:showing');
      }, TOAST_TIME_OUT);
    }
  }
);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
